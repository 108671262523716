import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../Pages/artistOfTheMonth/ArtistOfTheMonth.css'; // Add your styles here
import '../../Pages/bagofTheMonthDetail/BagofTheMonthDetail.css'; // Add your styles here

export const BagofTheMonthDetail = () => {
  const { id } = useParams(); // Get the bag's ID from the URL
  const [bagDetails, setBagDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate


  const fetchBagDetails = () => {
    const token = localStorage.getItem('token'); // Get token from local storage

    if (!token) {
      setError('Token not found. Please log in again.');
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    };

    fetch(`https://app.lvintage.com/admin/get/all/bag-of-month`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        const bag = result.result.find((bag) => bag.id === parseInt(id)); // Find the bag by ID
        if (bag) {
          setBagDetails(bag);
        } else {
          throw new Error('Bag not found');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBagDetails(); // Fetch bag details when the component mounts
  }, [id]);

  if (loading) {
    return <p>Loading bag details...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div className="container newd">
      <i className="fa fa-arrow-left" onClick={handleBack} style={{ "margin-top": "-42px", "margin-left": "-32px" }}></i>

      {bagDetails && (
        <div className="row">
          <div className="col-md-6">
        <h2 className='hding' style={{'margin-top': '0px'}}>Artist of the Month</h2>

            <p><strong>Name:</strong> {bagDetails.name}</p>
            <p><strong>Description:</strong> {bagDetails.description}</p>
          </div>
          <div className="col-md-6">
            {bagDetails.images && bagDetails.images.length > 0 ? (
              bagDetails.images.map((image, index) => (
                <img
                  key={index}
                  src={image} // Display each image
                  alt={`${bagDetails.name} ${index + 1}`}
                  className="bag-image"
                />
              ))
            ) : (
              <p>No images available</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BagofTheMonthDetail;
